import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import CaseStudies from 'components/organisms/case-studies';
import HalfBannerDescription, {
  HalfBannerDescriptionType,
} from 'components/organisms/half-banner-description';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import IndustriesServices, {
  IndustriesServicesType,
} from 'components/organisms/industries-services';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import Opinions, { OpinionsType } from 'components/organisms/opinions';
import PostsSectionRowSlider, {
  PostsSectionRowSliderType,
} from 'components/organisms/posts-section-row-slider';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb';

import { CaseStudiesType } from '../components/organisms/case-studies/case-studies';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
  offersPayrollPage: {
    template: {
      offersPayrollData: {
        bannerQuaternary: BannerPrimaryType;
        serviceDescription: TextBannerCardType;
        advantagesSection: TextBannerCardType;
        payrollServices: IndustriesServicesType;
        payrollTeam: ManagerSectionType;
        knowHowArticles: PostsSectionRowSliderType;
        joinToHrk: HalfBannerDescriptionType;
        opinions: OpinionsType;
        payrollCaseStudies: CaseStudiesType;
        otherServicesBlock: TextBannerCardType;
      };
    };
  };
};

export const OffersPayrollPage = ({
  pageContext,
  data: {
    offersPayrollPage: {
      template: {
        offersPayrollData: {
          bannerQuaternary: { heading, image, text },
          serviceDescription,
          advantagesSection,
          payrollServices,
          payrollTeam,
          knowHowArticles,
          joinToHrk,
          opinions,
          payrollCaseStudies,
          otherServicesBlock,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      titleTop={heading}
      subtitleTop={text}
      showDots
      positionTitle="left"
      image={image}
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <TextBannerCardSection
      {...serviceDescription}
      margin="0 auto"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      maxWidth={theme.wrapperXs}
      textColumn
      variantHeading="title1"
    />
    <TextBannerCardSection
      variantHeading="title1"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      {...advantagesSection}
      bgColor={theme.colors.grayscaleGray0}
    >
      {advantagesSection.cardList.map((item) => (
        <BannerCard
          {...item}
          bgColor={theme.colors.white}
          padding={theme.gap.md}
          mobilePadding={theme.gap.sm}
          key={item.text}
          shadow
        />
      ))}
    </TextBannerCardSection>
    <IndustriesServices {...payrollServices} bgColor={theme.colors.white} />
    <ManagerSection {...payrollTeam} margin="0" />
    <CaseStudies {...payrollCaseStudies} />
    <Opinions {...opinions} />
    <PostsSectionRowSlider
      {...knowHowArticles}
      margin="0"
      padding={`${theme.gap.xl} 0`}
      mobileMargin={`${theme.gap.md} 0 0 0`}
      bgColor={theme.colors.grayscaleGray0}
    />
    <LetsTalkBanner {...letsTalkBanner} margin="0 auto 0" reverseHalfBg />
    <TextBannerCardSection
      padding={`${theme.gap.xl} 0 0 0`}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      {...otherServicesBlock}
      variantHeading="title2"
      textColumn
    >
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => (
            <BannerCard {...item} margin="0" maxWidth="340px" key={item.text} />
          ))
        : null}
    </TextBannerCardSection>
    <HalfBannerDescription mobileMargin={`0 0 ${theme.gap.mdl} 0`} {...joinToHrk} />
  </Layout>
);

export const query = graphql`
  query OffersPayrollPage($id: String, $commonComponentsSlug: String) {
    offersPayrollPage: wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_OffersPayroll {
          templateName
          offersPayrollData {
            bannerQuaternary {
              text
              heading
              image {
                ...WpImageFragment
              }
            }
            serviceDescription {
              text
              heading
            }
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                heading
                icon {
                  name
                }
              }
            }
            payrollServices {
              heading
              subHeading
              text
              servicesList {
                heading
                text
                linkButton {
                  icon {
                    name
                  }
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
            payrollTeam {
              backgroundImage {
                ...WpImageFragment
              }
              businessCards {
                businessCard {
                  text
                  fullName
                  position
                  positionTitle
                  text
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                  image {
                    ...WpImageFragment
                  }
                }
              }
            }
            knowHowArticles {
              heading
              subTitle
              text
              posts: choosenPosts {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                      slug
                    }
                  }
                  tags {
                    nodes {
                      name
                      uri
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            payrollCaseStudies {
              subTitle
              heading
              imageCaseStudy {
                ...WpImageFragment
              }
              caseStudyPost {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  knowHowPostData {
                    postTemplate {
                      ... on WpKnow_how_Knowhowpostdata_PostTemplate_Author {
                        firstName
                        lastName
                        position
                      }
                    }
                  }
                  categories {
                    nodes {
                      name
                    }
                  }
                  tags {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            opinions {
              subTitle
              heading
              opionionsList {
                author
                fieldGroupName
                position
                text
              }
            }
            joinToHrk {
              bannerHeading: heading
              bannerSubHeading: subTitle
              bannerText: text
              bannerLinkButton: linkButton {
                icon {
                  name
                }
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  ariaLabel
                  external {
                    title
                    url
                  }
                }
              }
              bannerImage: image {
                ...WpImageFragment
              }
            }
            otherServicesBlock {
              subTitle
              heading
              text
              cardList: servicesList {
                heading
                text
                icon {
                  name
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersPayrollPage;
